import Head from "next/head";
import Logo from "~/assets/logo.svg";
import CsFlag from "~/assets/cs.svg";
import VnFlag from "~/assets/vn.svg";
import { ScrollLink } from "~/components/ScrollLink";
import "~/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import { cn } from "~/utils/cn";

export function Header(props: { variant?: "default" | "reservation" }) {
  const { t, i18n } = useTranslation();

  const router = useRouter();

  return (
    <>
      <Head>
        <meta
          name="description"
          content="Rigo &ndash; tlumočník v kapse. Mobilní aplikace, která vám pomůže překonat jazykovou bariéru"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#213842" />
        <meta name="msapplication-TileColor" content="#213842" />
        <meta name="theme-color" content="#213842" />
      </Head>
      <header
        id="header"
        className={cn(
          "fixed z-10 w-full bg-bg-secondary pb-6 pt-6 xl:w-full",
          props.variant === "reservation" && "bg-transparent"
        )}
      >
        <div className="mx-auto flex w-10/12 items-center justify-between xl:w-full xl:max-w-[1200px]">
          {router.pathname === "/" ? (
            <ScrollLink href="#header">
              <Logo />
            </ScrollLink>
          ) : (
            <Link href="/">
              <Logo />
            </Link>
          )}
          <nav className="flex gap-x-5 sm:gap-x-7">
            {i18n.language == "cs" && (
              <span
                onClick={() => void i18n.changeLanguage("vn")}
                className="overline-large link inline-flex items-center gap-2 uppercase text-text-primary hover:cursor-pointer"
              >
                <VnFlag /> Tiếng Việt
              </span>
            )}
            {i18n.language == "vn" && (
              <span
                onClick={() => void i18n.changeLanguage("cs")}
                className="overline-large link inline-flex items-center gap-2 uppercase text-text-primary hover:cursor-pointer"
              >
                <CsFlag /> Čeština
              </span>
            )}
            {props.variant !== "reservation" && (
              <Link
                href="/rezervace"
                className="overline-large link uppercase text-text-primary"
              >
                {t("menu.book-us")}
              </Link>
            )}
          </nav>
        </div>
      </header>
    </>
  );
}
