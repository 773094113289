import {initReactI18next} from "react-i18next";
import i18n from "i18next";
import translationCs from './translations/cs.json'
import translationVn from './translations/vn.json'

const resources = {
  vn: {
    translation: translationVn
  },
  cs: {
    translation: translationCs
  }
};

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'cs', // default language
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
