import Link, { type LinkProps } from "next/link";
import React, { type PropsWithChildren } from "react";

type AnchorProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof LinkProps
>;

type ScrollLinkProps = AnchorProps & LinkProps & PropsWithChildren;

const ScrollLink = ({ children, ...props }: ScrollLinkProps) => {
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const targetId = e.currentTarget.href.replace(/.*#/, "");
    const elem = document.getElementById(targetId);
    const headerHeight = document.getElementById("header")?.clientHeight ?? 0

    const top = targetId === "header" ? 0 :
      (elem?.getBoundingClientRect().top ?? 0) + window.scrollY - headerHeight - 40

    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  };
  return (
    <Link {...props} onClick={handleScroll}>
      {children}
    </Link>
  );
};

export { ScrollLink };
